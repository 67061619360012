// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--1GOzQ";
export var box___1RSff = "PlasmicFeatures-module--box___1RSff--1iUH-";
export var header = "PlasmicFeatures-module--header--1Ld5D";
export var section = "PlasmicFeatures-module--section--AVnp2";
export var box__wPf0D = "PlasmicFeatures-module--box__wPf0D--3XV_G";
export var box__znLb = "PlasmicFeatures-module--box__znLb--1Hq00";
export var box___6HoNs = "PlasmicFeatures-module--box___6HoNs--3avif";
export var box__mzRo = "PlasmicFeatures-module--box__mzRo--3S677";
export var bullet__tcbAm = "PlasmicFeatures-module--bullet__tcbAm--TNcTt";
export var bullet__tZWq1 = "PlasmicFeatures-module--bullet__tZWq1--3knhO";
export var bullet__qDpGh = "PlasmicFeatures-module--bullet__qDpGh--5sI0o";
export var box__jjeg4 = "PlasmicFeatures-module--box__jjeg4--1QrFz";
export var box__kuqI = "PlasmicFeatures-module--box__kuqI--1mQrw";
export var box__mlSrp = "PlasmicFeatures-module--box__mlSrp--dytLm";
export var img__vUcSj = "PlasmicFeatures-module--img__vUcSj--3kzRT";
export var img__hfbyP = "PlasmicFeatures-module--img__hfbyP--3KUDp";
export var homeCta = "PlasmicFeatures-module--homeCta--3_pJJ";
export var footer = "PlasmicFeatures-module--footer--2Pg4E";